var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: ["icon-holder", _vm.name, _vm.width, _vm.height, _vm.display],
    on: {
      click: _vm.clickHandler
    }
  }, [_c("svg", {
    staticStyle: {
      display: "none"
    },
    attrs: {
      xmlns: "http://www.w3.org/2000/svg"
    }
  }, [_c("symbol", {
    attrs: {
      id: _vm.name
    }
  }, [_c("path", {
    attrs: {
      d: _vm.path,
      stroke: "0",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd"
    }
  })])]), _c("svg", {
    class: ["icon-" + _vm.variant, _vm.width, _vm.height, _vm.align, _vm.disabled ? "tw-opacity-50 select-none" : ""],
    style: {
      fill: _vm.fill || _vm.color
    },
    attrs: {
      viewBox: _vm.viewbox
    }
  }, [_c("use", {
    attrs: {
      "xlink:href": `#${_vm.name}`
    }
  })])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };